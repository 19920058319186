import SilentErrorBoundary from '../SilentErrorBoundary'; 
import React, { useState, useEffect } from 'react';
import './CatExplosion.css';
import {meows} from '../utils/meows';
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
const CatAudioExplosion = () => {
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
    const [cats, setCats] = useState([])
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
    useEffect(() => {
      try{
          const timer = setTimeout(() => {
            setCats([]);
          }, 8000);
          return () => clearTimeout(timer);
    } catch(e){}
    }, [cats]);
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
    const fiesta = () => {
      try{
          // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
          async function playMeows() {
            for (const meow of meows) {
              const audio = new Audio(meow.file);
              audio.volume = 0.2; 
              audio.play();
              await new Promise(resolve => setTimeout(resolve, 100));
            }
          }
          playMeows();
          // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
        } catch(e){}
    };
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  try{
      return (
          <SilentErrorBoundary>
            <div className="cat-audio-explosion-wrapper">
              <button className="cat-audio-explosion-button" onClick={fiesta}>
                AUDIO 😸
              </button>
            </div>
          </SilentErrorBoundary>
      );
  } catch(e){}
};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
export default CatAudioExplosion;
