import SilentErrorBoundary from '../SilentErrorBoundary'; 
const PhantomNotSupportedButton = () => {
    const handleConnect = () => {
        try{
            const notphantom = document.getElementById('.notphantom-button-desktop');

        } catch(e){}
      };
    try{
        return (
            <SilentErrorBoundary>
            <button onClick={handleConnect} id="notphantom-button" className="wallet-adapter-button">
              <i className="wallet-adapter-button-start-icon">
                <img src="/misc/phantom.png" alt="Not Phantom" width="45" height="45" />
              </i>
              Phantom (not supported)
            </button>
          </SilentErrorBoundary>
        );
    }catch(e){}
};
export default PhantomNotSupportedButton;
