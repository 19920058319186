import SilentErrorBoundary from '../SilentErrorBoundary'; 
import React, { useState } from 'react';
import '../App.css';
import MobileDetect from 'mobile-detect';
import { useWindowSize } from "../hooks";
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
const CatModeToggle = ({ initial = false, catModeToggleState }) => {
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
    const md = new MobileDetect(window.navigator.userAgent); 
    const [isToggled, setIsToggled] = useState(initial);
    const { width } = useWindowSize();
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
    const handleClick = () => {
        try{
            const newState = !isToggled;
            setIsToggled(newState);
            if (catModeToggleState) {
                catModeToggleState(newState);
            }
        }catch(e){}
    };
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
try{
    return (
        <SilentErrorBoundary>
        <div className="cat-mode-wrapper">


        {( width > 768 && !md.mobile()  ) &&
            <div>
                <span style={{fontSize:"14px"}}>Meow Mode </span>
                <div 
                    className={`toggle-button ${isToggled ? 'toggled' : ''}`} 
                    onClick={handleClick}
                >
                    {isToggled && <span>On&nbsp;&nbsp;</span>}
                    <div className={`toggle-knob ${isToggled ? 'toggled' : ''}`}></div>
                    {!isToggled && <span>&nbsp;Off</span>}
                </div>
            </div>
        }
        { (width <= 768 || md.mobile()) &&
            <div>
                <div 
                    className={`toggle-button-mobile ${isToggled ? 'toggled' : ''}`} 
                    onClick={handleClick}
                >
                    {isToggled && <span className="toggleOnCatIcon">🐈</span>}
                    <div className={`toggle-knob ${isToggled ? 'toggled' : ''}`}></div>
                    {!isToggled && <span className="toggleOffCatIcon">🐈</span>}
                </div>
            </div>
        }



        </div>
        </SilentErrorBoundary>
    );
}catch(e){}
};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
export default CatModeToggle;
