import SilentErrorBoundary from '../SilentErrorBoundary'; 
import React, { useState, useEffect } from 'react';
import './CatExplosion.css';
import {moremeows} from '../utils/moremeows';
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
const CatExplosionMobile = ({catmode }) => {
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  const emojis = ["🐱", "😸", "😻", "🐈", "😽", "😼"];
  if(catmode){
    //emojis.push("😹");
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  const [cats, setCats] = useState([])
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
    useEffect(() => {
      try{
        const updateMousePosition = async (event) => {
          setMousePosition({ x: event.clientX, y: event.clientY });
      };
      window.addEventListener('mousemove', updateMousePosition);
      return () => window.removeEventListener('mousemove', updateMousePosition);
      } catch(e){}
    }, []);
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    useEffect(() => {
      try{
        const triggerFiesta = async () => {
            if (catmode) {
                const button = document.getElementsByClassName('cat-explosion-button')[0];
                if (button) {
                    button.click();
                }
            // - - - - - -
          }
        };
        window.addEventListener('mouseup', triggerFiesta);
        return () => window.removeEventListener('mouseup', triggerFiesta);
      } catch(e){}
    }, [catmode]);
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    useEffect(() => {
        try{
          const catduration = catmode? 3000 : 10000;
            const timer = setTimeout(() => {
              setCats([]);
            }, catduration);
            return () => clearTimeout(timer);
        } catch(e){}
    }, [cats]);
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  const fiesta = async () => {
    try{
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        try{
          async function playMeows() {
            const randomIndex = Math.floor(Math.random() * moremeows.length);
            const randomMeow = moremeows[randomIndex];
            const audio = new Audio(randomMeow.file);
            audio.volume = 0.3; 
            audio.play();
            new Promise(resolve => setTimeout(resolve, 100));
          }
          await playMeows();
        } catch(e){}
         // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
         const playEmojis = () => {
        const catloop = catmode? 25 : 300;
        const catwait = catmode? 0.25 : 3;
        for (let i = 0; i < catloop; i++) {
          setTimeout(() => {
            let newCat;
                newCat = {
                  id: Math.random(),
                  emoji: emojis[Math.floor(Math.random() * emojis.length)],
                  startX: catmode? mousePosition.x : 750,
                  startY: catmode? mousePosition.y : 1050,
                  moveX: (Math.random() - 0.5) * 500 + (i -(0.994*i)),
                  moveY: (Math.random() - 0.5) * 800 + (i -(0.9967*i)),
                  rotation: Math.random() * 360 , 
                  duration: Math.random() * 0.8 + 1, 
                };
            setCats(prevCats => [...prevCats, newCat]);
          }, i * catwait); 
        }
      }
      playEmojis();
      } catch(e){}
      
  };
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  try{
      return (
        <SilentErrorBoundary>
          <div className="explosion-wrapper" style={{zIndex: catmode? 99999 : 99999}}>
          <button className="cat-explosion-button" onClick={fiesta}>
              MOBILE 😸
            </button>
            {cats.map(cat => (
              <span 
                key={cat.id}
                className="emoji"
                style={{
                  transform: `{scale(${0.25})}`,
                  left: `${cat.startX + 200}px`,
                  top: `${cat.startY}px`,
                  transform: `rotate(${cat.rotation   }deg)`,
                  animation: `fly ${cat.duration}s ease-out forwards`,
                  '--moveX': `${cat.moveX * 0.7 * Math.LOG2E }px`,
                  '--moveY': `${cat.moveY * 1.7 * Math.LOG2E}px`,
                  zIndex: catmode? 999999 : 999999,
                  marginLeft: catmode? "0x" : "-620px",
                }}
              >
                {cat.emoji}
              </span>
            ))}
          </div>
          </SilentErrorBoundary>
      );
  } catch(e){}
};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
export default CatExplosionMobile;
