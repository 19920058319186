  export const moremeows = [
    { name: "meow_001", file: "/meows/meow_001.mp3" },
    { name: "meow_002", file: "/meows/meow_002.mp3" },
    { name: "meow_003", file: "/meows/meow_003.mp3" },
    { name: "meow_004", file: "/meows/meow_004.mp3" },
    { name: "meow_005", file: "/meows/meow_005.mp3" },
    { name: "meow_006", file: "/meows/meow_006.mp3" },
    { name: "meow_007", file: "/meows/meow_007.mp3" },
    { name: "meow_008", file: "/meows/meow_008.mp3" },
    { name: "meow_009", file: "/meows/meow_009.mp3" },
    { name: "meow_010", file: "/meows/meow_010.mp3" },
    { name: "meow_011", file: "/meows/meow_011.mp3" },
    { name: "meow_012", file: "/meows/meow_012.mp3" },
    { name: "meow_013", file: "/meows/meow_013.mp3" },
    { name: "meow_014", file: "/meows/meow_014.mp3" },
    { name: "meow_015", file: "/meows/meow_015.mp3" },
    { name: "meow_016", file: "/meows/meow_016.mp3" },
    { name: "meow_017", file: "/meows/meow_017.mp3" },
    { name: "meow_018", file: "/meows/meow_018.mp3" },
    { name: "meow_019", file: "/meows/meow_019.mp3" }
  ];